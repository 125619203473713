// chat-list-container
.chat-list {
    .header {
        width: 100%;
        padding: 10px;
        background: #fff !important;
        z-index: 9;

        // avatar-container
        .avatar-container {
            .avatar-image-container {
                width: 40px !important;
                height: 40px !important;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            .avatar-name-container {
                padding-top: 5px;
                padding-left: 10px;

                p {
                    color: #000;
                    font-size: 20px !important;
                }
            }
        }
    }

    .body {
        position: absolute;
        top: 106px;
        bottom: 0;
        width: 100%;
        overflow-y: auto;
        padding: 10px;
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
        scrollbar-width: auto;
        scrollbar-color: #dfdfdf #ffffff;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 15px;
    }

    *::-webkit-scrollbar-track {
        background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #dfdfdf;
        border-radius: 10px;
        border: 3px solid #ffffff;
    }
}
