@import './colors.scss';

/* Flex Box */

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.flex-column {
  -ms-flex-direction: column !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

// Column padding for admin dashboard col padding
// .col-padding {
//     padding: 10px;
//     @media (min-width: 992px) {
//         padding-left: 20px;
//         padding-right: 20px;
//     }
// }
// p {
//     font-size: 13px;
//     font-weight: 400;
//     color: $gray-600;
// }
// Form
// form {
//     font-family: 'Roboto Condensed', sans-serif;
//     .form-group {
//         .form-control {
//             width: 100%;
//             color: #000;
//             font-size: 15px;
//             min-height: 42px;
//             background: #e9e8e8;
//             border: 1px solid #e9e8e8;
//         }
//         .form-control:focus {
//             background: #fff;
//             border: 1px solid #e9e8e8;
//         }
//         .error {
//             border: 1px solid $danger !important;
//         }
//     }
//     a {
//         font-size: 14px;
//         font-weight: 500;
//         color: $primary !important;
//         &:hover {
//             color: $secondary !important;
//         }
//     }
// }
// Divider text
.divider-text {
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $gray-600;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 15px 0;

  &::before,
  &::after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background-color: $gray-400;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
}

.bg-primary-light {
  background: rgb(90, 139, 255) !important;
}

.bg-dark-orange {
  background: rgb(252, 94, 94) !important;
}
.bg-green-light {
  background: rgb(95, 255, 156) !important;
}

/* Avoid breaking the order details table */
// .order-invoice-details {
//   page-break-inside: avoid; /* Prevents the table from being split */
//   width: 100%;
// }

/* Initially hide the invoice from the screen */
.invoice-template {
  display: none;
  position: relative;
  font-size: 18px;
  // font-family: 'Roboto Condensed', sans-serif;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.invoice-template2 {
  // display: block;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  position: relative;
  font-size: 16px !important;

  // font-family: 'Roboto Condensed', sans-serif;
}

.invoice-watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1; /* Adjust the transparency of the watermark */
  z-index: 99; /* Lower z-index to ensure content appears above */
  pointer-events: none; /* Ensures that the watermark doesn't interfere with interactions */
}

.watermark-logo {
  width: 600px; /* Adjust the size of the watermark */
  height: auto;
}

/* Show the invoice only during printing */
@media print {
  .invoice-template {
    display: block;
    visibility: visible;
    overflow: hidden;
    position: relative;
  }
  .invoice-template2 {
    display: none;
  }
  .hide-during-print {
    display: none;
  }

  body.print-mode * {
    display: none;
  }

  body.print-mode .invoice-template {
    display: block;
  }

  /* Hide everything except the invoice during print */
  body * {
    visibility: hidden;
  }

  /* Custom footer styles for print */
  .print-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #696969;
    margin: 5px 0px;
  }

  /* Page settings */
  @page {
    margin: 10mm; /* Adjust to reduce extra margins */
    size: auto;
  }

  /* Ensure the invoice is visible during print */
  .invoice-template,
  .invoice-template * {
    visibility: visible;
  }
  .invoice-template2,
  .invoice-template2 * {
    visibility: visible;
  }

  /* Ensure proper positioning and layout of the invoice during print */
  .invoice-template {
    position: absolute;
    visibility: visible;
    left: 0;
    top: 0;
    width: 100%;
  }

  .hide-during-print {
    display: none;
  }

  /* Ensure tables are fully printed */
  .invoice-table {
    width: 100%;
  }

  /* Ensure table rows do not break mid-row */
  .order-invoice-table tr {
    page-break-inside: auto;
  }
}

@page {
  margin: 10mm; /* Adjust to reduce extra margins */
  size: auto;
}

/* Regular CSS for invoice styling */
.order-invoice-table td,
.order-invoice-table th {
  padding: 8px;
  text-align: left;
}
.order-invoice-details th,
.order-invoice-details td {
  border: 1px solid #ddd;
  padding: 5px; /* Reduce padding */
  line-height: 1.4; /* Adjust line height to reduce row height */
  font-size: 19px; /* Optionally adjust font size for compact appearance */
}
.order-invoice-total-row {
  font-weight: bold;
  text-align: end;
}

.order-invoice-terms-section {
  margin-top: 20px;
}
.order-invoice-signature {
  margin-top: 50px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.invoice-logo-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
}

.invoice-info-section {
  flex: 1;
  display: flex;
  align-items: start;
  justify-content: center;
  font-size: 18px;
}

.invoice-info-section p {
  text-align: center;
  margin: 0;
}

.invoice-details-section {
  flex: 1;
  text-align: right;
}

.invoice-barcode {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.invoice-row {
  display: flex;
  justify-content: space-between;
}

.invoice-label {
  font-weight: 600;
}

.invoice-value {
  text-align: right;
}

.invoice-row {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* same as mt-4 in Bootstrap */
  align-items: start;
}

.invoice-customer-info {
  flex: 1;
  padding-right: 20px; /* To create space between the info and address */
}

.invoice-customer-address {
  text-align: left;
  flex: 1;
  padding: 0 20px; /* Add padding to ensure the address stays on the left */
}

.invoice-title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-top: 10px; /* same as my-2 in Bootstrap */
}

.invoice-border {
  border-top: 1px solid #5f5e5e;
  border-bottom: 1px solid #5f5e5e;
}

.invoice-section-container {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Add space between the table and terms */
  flex-wrap: wrap; /* Ensures it wraps on smaller screens */
  margin-top: 10px;
}

.invoice-table-container,
.order-invoice-terms-section {
  flex: 1;
  min-width: 300px; /* Ensures the sections do not collapse */
}
.invoice-table-container {
  margin-top: 10px; /* mt-4 equivalent */
  width: 100%;
}

.invoice-table {
  width: 100%;
}

.invoice-table-bordered {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table-bordered th,
.invoice-table-bordered td {
  border: 1px solid #616060;
  padding: 8px;
  text-align: center;
}

.invoice-table-bordered th {
  font-weight: bold;
}

.invoice-table-bordered td {
  background-color: #fff;
}

.invoice-signature {
  display: flex;
  justify-content: space-between;
  margin-top: 30px; /* Adjust spacing as needed */
}

.invoice-signature-customer p,
.invoice-signature-authorized p {
  text-align: left; /* Left-align text for both signatures */
}

.invoice-signature-authorized {
  text-align: right; /* Right-align the authorized signature */
}

/* Barcode print css */

.print-page-layout {
  display: block;
  // border: 1px solid #ccc;
  margin: 2px auto;
  page-break-after: always;
}

.print-page-a4 {
  width: 8.25in;
  height: 11.6in;
  // padding: 0.1in 0 0 0.1in;
  display: block;
  border: 1px solid #ccc;
  margin: 10px auto;
  page-break-after: always;
}

.print-page-non-a4 {
  width: 8.45in;
  height: 10.3in;
  // padding-top: 0.1in;
  display: block;
  border: 1px solid #ccc;
  margin: 6px auto;
  page-break-after: always;
}

.barcode-item {
  display: flex;
  flex-direction: column; /* Ensure column layout */
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  // padding: 5px 0px;
}

.display-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  padding: 5px;
}

.per-page-40 {
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
}

.per-page-30 {
  grid-template-columns: 1fr 1fr 1fr !important;
}

.per-page-24 {
  grid-template-columns: 1fr 1fr 1fr !important;
}

.per-page-20 {
  grid-template-columns: 1fr 1fr !important;
}

.per-page-18 {
  grid-template-columns: 1fr 1fr 1fr !important;
}

.per-page-14 {
  grid-template-columns: 1fr 1fr !important;
}

.per-page-12 {
  grid-template-columns: 1fr 1fr 1fr !important;
}

.per-page-10 {
  grid-template-columns: 1fr 1fr !important;
}
.per-page-1 {
  grid-template-columns: 1fr !important;
}

@media print {
  .print-page-a4 {
    border: 0;
    margin: 0 auto;
    page-break-after: always;
  }

  .print-page-non-a4 {
    border: 0;
    margin: 0 auto;
    page-break-after: always;
  }

  .pagebreak {
    page-break-before: always;
  }
}

.price-tag {
  transform: rotate(-90deg); /* Rotate the price tag 90 degrees */
  transform-origin: left center; /* Adjust the rotation origin */
  position: absolute; /* Make the price tag float beside the barcode */
  top: 40px;
  left: -20px; /* Position it to the left of the barcode */
  font-size: 8px; /* Adjust the font size as needed */
  white-space: nowrap; /* Ensure text doesn't wrap */
}
.exp-tag {
  transform: rotate(-90deg); /* Rotate the price tag 90 degrees */
  transform-origin: right center; /* Adjust the rotation origin */
  position: absolute; /* Make the price tag float beside the barcode */
  bottom: 40px;
  right: -20px; /* Position it to the left of the barcode */
  font-size: 8px; /* Adjust the font size as needed */
  white-space: nowrap; /* Ensure text doesn't wrap */
}
