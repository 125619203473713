@import "../../colors.scss";
.shift-card {
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 3px;
    position: relative;
    min-width: 250px;
    height: 165px;
    margin-top: 7px;
    .badge {
        font-size: 13px;
    }
    
}

.edit-button {
    top: 5px;
    right: 5px;
    border-radius: 20px;
    height: 40px;
    width: 40px;
}
.delete-button {
    top: 5px;
    right: 45px;
    border-radius: 20px;
    height: 40px;
    width: 40px;
}

.extra-style {
    margin-top: 7px !important;
}

.create-card {
    cursor: pointer;
    text-align: center;
    .create-icon {
        margin-top: 45px;
    }
}

.nav-tabs {
    border: 0 !important;
    .nav-item {
        border: 0;
        color: #000;
        font-size: 13px;
        font-weight: 400;
        margin-right: 5px;
        padding: 7px 14px;
        margin-bottom: 0;
        background: #dfdfdf;
        border-radius: 4px;
        text-transform: capitalize;
        @media(max-width: 576px) {
            padding: 6px 5px
        }
    }
    .active {
        border: 0;
        color: #fff !important;
        border-radius: 4px;
        background: $primary !important;
    }
}

.react-date-picker__wrapper {
    border: none;
}

.attendanceDateShift{
    .form-group .form-control{
        min-width: 100px !important;
        font-size: 14px !important;
        min-height: 0 !important;
        background: #fff !important;
        border-radius: 5px !important;
    }
}


.circleStyle{
    height: 14px !important;
    width: 14px !important;
    border-radius: 50% !important;

}

.greenCircle{
    background: #4EE121 !important;
}
.blueCircle{
    background: #5487FF !important;
}
.pinkCircle{
    background: #FF72FF !important;
}
.redCircle{
    background: #FF5A5A !important;
}

