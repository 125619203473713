.drawer-container {
  ::-webkit-scrollbar {
    width: 0px;
  }
  @media (max-width: 992px) {
    .backdrop {
      position: fixed;
      top: 0;
      left: 0%;
      width: 100vw;
      height: 100vh;
      z-index: 9999;
      overflow-y: auto;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.4s;
      background: rgba(0, 0, 0, 0.548);
    }
    .open-backdrop {
      visibility: visible !important;
      opacity: 1 !important;
      transition-delay: 0s !important;
    }
    .drawer {
      position: fixed;
      top: 0;
      left: -100%;
      width: 250px;
      height: 100%;
      border-radius: 0;
      overflow-y: auto;
      transition: 0.3s;
      background-image: url('../../assets/bar.jpg');
      background-position: bottom;
      background-size: cover;
      overflow-x: hidden;
      transition-timing-function: ease-out;
      z-index: 99999;
      .drawer-body {
        // padding: 15px;
      }
    }
    .open-drawer {
      left: 0 !important;
    }
  }
  @media (min-width: 992px) {
    .drawer {
      position: fixed;
      top: 0;
      left: 0;
      width: 250px;
      height: 100vh;
      overflow-y: auto;
      //   background: #fff;
      background-image: url('../../assets/bar.jpg');
      background-position: bottom;
      background-size: cover;
      overflow-x: hidden;
      z-index: 99;
      border-right: 1px solid #dfdfdf;
      .drawer-body {
        padding-top: 75px;
        // padding-left: 15px;
        // padding-right: 15px;
        padding-bottom: 15px;
      }
    }
  }
}
.help-card {
  width: 100%;
  background-color: #3496ff97; /* Bright blue background */
  border-radius: 10px;
  padding: 10px 20px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: rgb(255, 255, 255);
}

.help-card .icon-container {
  background-color: transparent;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 10px;
}

.help-card .icon {
  width: 30px;
  height: 30px;
}

.help-card .card-title {
  font-weight: bold;
  font-size: 18px;
}

.help-card .btn {
  border-radius: 5px;
  font-weight: bold;
  background-color: rgb(205, 174, 17);
}
