.overflow__hidden {
    overflow: hidden !important;
}

.overflow__x__hidden {
    overflow-x: hidden !important;
}

.overflow__y__hidden {
    overflow-y: hidden !important;
}
