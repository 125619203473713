.orderpage-navigator {
    position: relative;
    top: -32px;
    right: 17px;
    .dropdown {
        .dropdown-toggle {
            width: 38px;
            height: 38px;
            padding: 0;
            box-shadow: none;
            border: 0 !important;
            background-color: none !important;
            color: #000 !important;
            &:focus {
                box-shadow: none !important;
            }
            &:active {
                background: none;
            }
            &:after {
                display: none;
            }
        }
        .dropdown-menu {
            padding: 0;
            min-width: 250px;
            border-radius: 4px;
            border: 1px solid #dfdfdf;
            .dropdown-header {
                padding: 20px !important;
                h6 {
                    color: #000;
                    font-weight: 500;
                    font-size: 14px;
                }
                small {
                    font-size: 11px;
                    font-weight: 700;
                }
            }
            .dropdown-body {
                padding-left: 20px !important;
                padding-bottom: 20px !important;
                .dropdown-item {
                    color: #6c757d;
                    font-size: 13px;
                    padding: 10px 0px;
                    transition: 0.3s all;
                    font-weight: 400;
                    text-transform: capitalize;
                    &:hover {
                        color: #042893;
                        background: none;
                    }
                    span {
                        padding-left: 10px;
                    }
                }
            }
        }
    }
    .btn {
        background: none !important;
        padding: 0 !important;
        width: 38px;
        height: 38px;
    }
}