// Single menu item

.single-menu {
  width: 100%;
  .btn {
    width: 100%;
    display: block;
    color: #6c757d;
    text-align: left;

    .menu-icon {
      font-size: 20px;
    }

    .menu-title {
      font-size: 13px;
      font-weight: 400;
      margin-left: 10px;
    }
  }

  .isActive {
    color: #000 !important;
  }
}

// Sub-menu container
.sub-menu-container {
  .bg-gray {
    background-color: #080808;
  }
  overflow: hidden;
  .isActive {
    background-color: #808080 !important;
  }
  .glass-color {
    // background-color: rgb(17, 38, 105);
    background-color: #002244;
    opacity: 0.8;
    border-bottom: 1px solid rgb(65, 103, 230);
  }
  .menu-icon {
    font-size: 20px;
    color: #f8f8f8;
  }

  .menu-title {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .btn {
    width: 100%;
    display: block;
    color: #6c757d;
    text-align: left;
  }

  .isActive {
    .menu-icon {
      color: #ffffff !important;
    }

    .menu-title {
      color: #ffffff !important;
    }
  }

  .sub-menu {
    width: 100%;
    cursor: pointer;
    padding: 6px 12px;
    text-align: left;

    .menu-arrow {
      margin-top: 2px;
      color: #f8f8f8;

      .arrow {
        -moz-transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }

      .down {
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }

  .sub-menu-body {
    opacity: 0;
    display: none;
    overflow: hidden;
    // margin-left: 10px;
    -webkit-transition: all 0.3s ease 0.15s;
    -moz-transition: all 0.3s ease 0.15s;
    -o-transition: all 0.3s ease 0.15s;
    -ms-transition: all 0.3s ease 0.15s;
    transition: all 0.3s ease 0.15s;
    background-color: rgb(186, 200, 245);
  }

  .show {
    opacity: 1;
    display: block;
  }
}
