.warning-outside {
    padding: 11px;
    background-color: #FFEAC3;
}

.warning-inside {
    padding: 8px;
    position: relative;
    top: -12px;
    left: -16px;
    background-color: #FFBB38;
}

.ordertitle__extra {
    color: #343C6A;
    font-weight: bold;
    font-size: 15px;
}

.orderquantity {
    color: #96A9C8;
    font-weight: bold;
    font-size: 15px;
}

.product-name {
    color: #343C6A;
    font-size: 16px;
    font-weight: bold;
}

.title-text {
    color: #96A9C8;
    font-weight: bold;
    font-size: 14px;
}

.title-value {
    color: #5B7C9F;
    font-weight: bold;
    font-size: 14px;
}

.image-size {
    width: 100px !important;
}