    .customer-name {
        color: #343C6A;
        font-size: 14px;
        font-weight: normal;
    }
    
    .order-text {
        color: #96A9C8;
        font-weight: normal;
        font-size: 14px;
    }
    
    .order-value {
        color: #5B7C9F;
        font-weight: normal;
        font-size: 14px;
    }
    
    .image-size {
        width: 100px !important;
    }
    
    .customertitle_extra {
        color: #343C6A;
        font-weight: normal;
        font-size: 18px;
    }