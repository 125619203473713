.search-component {
    position: relative;

    form {
        .input-container {
            position: relative;
            overflow: hidden;
            border-radius: 25px;

            .form-control {
                font-size: 14px;
                padding-left: 12px;
                padding-right: 35px;
                border-radius: 25px;
                height: 37px !important;
                border: 1px solid #e9e8e8;

                &:focus {
                    border: 1px solid #dfdfdf;
                }
            }

            .error {
                border: 1px solid #DC3545 !important;
            }

            .btn {
                position: absolute !important;
                top: 0 !important;
                right: 0 !important;
                border-radius: 0 !important;
                padding: 3px 10px !important;
                height: 34px !important;
                background: none !important;

                i {
                    color: #0D6EFD;
                }
            }
        }

        // button spin loader
        .btn-loader {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid #dfdfdf;
            border-top: 2px solid #2962ff;
            -webkit-animation: spin 0.7s linear infinite;

            /* Safari */
            animation: spin 0.7s linear infinite;
        }

        /* Safari */
        @-webkit-keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    // suggestion container
    .suggestion-container {
        position: absolute;
        top: 40px;
        right: 0;
        width: 250px;
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        background: #fff;
        z-index: 99;
        border-radius: 4px;
        border: 1px solid #dfdfdf;

        p {
            font-size: 13px;
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                cursor: pointer;
                color: #6c757d;
                font-size: 13px;
                padding: 15px;
                transition: 0.3s all;
                font-weight: 400;
                text-transform: capitalize;
                border-bottom: 1px solid #6c757d1e;

                &:hover {
                    color: #000;
                    background: #6c757d11;
                    border-bottom: 1px solid #6c757d11;
                }
            }
        }
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
        scrollbar-width: auto;
        scrollbar-color: #2962ff #ffffff;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 12px;
    }

    *::-webkit-scrollbar-track {
        background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #2962ff;
        border-radius: 10px;
        border: 3px solid #ffffff;
    }
}
