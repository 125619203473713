.auth-container {
    padding-top: 50px;
    padding-bottom: 50px;

    .card {
        margin: auto;

        @media (min-width: 576px) {
            width: 500px;
        }
    }
}
