
.message-container{
    width: 100%;
    margin-bottom: 25px;

    .avatar-container{
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;

        img{
            width: 50px;
            height: 50px;
        }
    }

    .content-container{
        .content-box-left{
            color: #000 !important;
            background: #E4E6EB !important;
        }

        .content-box-right{
            color: #fff !important;
            background: rgb(0, 132, 255) !important;
        }

        .content-box{
            padding: 7px 12px 8px;
            border-radius: 16px;
            max-width: 250px;

            p{
                font-size: 15px;
                line-height: 25px;
            }


            @media(min-width: 576px){
                max-width: 300px;
            }

            @media(min-width: 768px){
                max-width: 350px;
            }

            @media(min-width: 1200px){
                max-width: 500px;
            }

            @media(min-width: 1440px){
                max-width: 600px;
            }
        }
    }
}