.master {
  min-height: 100vh;

  .main {
    padding-top: 50px;

    @media (min-width: 992px) {
      padding-bottom: 20px;
      padding-left: 280px;
    }
  }
  .only-pos {
    padding-top: 0px;

    @media (min-width: 992px) {
      padding-bottom: 0px;
      padding-left: 0px;
    }
  }
}
