@import '../../colors.scss';

.sms-item-card {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  border-radius: 16px !important;
  margin-bottom: 30px !important;
  border: 1px solid #063cdd34 !important;
  color: $primary !important;
}
.sms-item-card-dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: start;
  border-radius: 16px !important;
  margin-bottom: 30px !important;
  border: 1px solid #063cdd34 !important;
  color: white !important;
  background-color: #07a90e !important;
}
.sms-item-card-dashboard-two {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: start;
  border-radius: 16px !important;
  margin-bottom: 30px !important;
  border: 1px solid #063cdd34 !important;
  color: white !important;
  background-color: #06adad !important;
}
.sms-item-card-dashboard-three {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: start;
  border-radius: 16px !important;
  margin-bottom: 30px !important;
  border: 1px solid #063cdd34 !important;
  color: white !important;
  background-color: #700088 !important;
}
.sms-item-card-dashboard-four {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: start;
  border-radius: 16px !important;
  margin-bottom: 30px !important;
  border: 1px solid #063cdd34 !important;
  color: white !important;
  background-color: #001ba8 !important;
}
